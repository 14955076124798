@import "../../../../styles/scss/index.scss";

.pro-sidebar-sadmin {
    background-color: $spacecadet;
    box-shadow: 5px 1px 10px rgba(22, 24, 26, 0.07) !important;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    transition: 350ms;
    z-index: 1;
    margin-top: 85px;
}

ul {
    padding: 0;
}
a{
    text-decoration: none;
    color: inherit;
}
.pro-sidebar-sadmin-hide {
    display: none;
}

.pro-sidebar-sadmin-bg {
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 5px 1px 10px rgba(22, 24, 26, 0.07) !important;
}

.menu-container {
    background-color: $isabelline;
}

.menu-item-sadm {
    padding: 15px 20px;
    margin: 10px 8px;
    transition: background-color 0.3s ease;
    list-style-type: none;
    text-decoration: none !important;
    color: $white;
    font-weight: 600;
    &:focus {
        outline: 0;
        box-shadow: none !important;
      }
      &:hover {
        color: $oasis !important;
      }
}

.menu-item-sadm.active {
    background-color: $isabelline;
    border-radius: 5px;
    text-decoration: none !important;
    color: $onyx;
    font-weight: 600;
    transition: color 0.3s ease;
    &:hover {
        color: $davysgrey !important;
      }
}

.menu-icon-sadm {
    font-size: 16px;
    border: 1px solid rgba(22, 24, 26, 0.07);
    border-radius: 4px;
    padding: 5px;
    margin-right: 10px;
    color: $davysgrey;
    background-color: $isabelline;
}

.sadm-link-txt {
    text-decoration: none !important;
    color: $white;
    transition: color 0.3s ease;
}

.sadm-link-txt:hover {
    color: #ff6600;
}
.hr-nav{
    color: $white;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }
  @media only screen and (max-width: 576px) {
    .menu-item-sadm.active {
        padding: 20px 20px;
        margin: 0 2px;
    }
    .pro-sidebar-sadm-bg {
        padding: 10px 20px;
    }
  }