@import url("https://fonts.googleapis.com/css?family=Poppins");

.card{
  width: 100% !important;
}
.align-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.do-you-know-header-text{
  color: $roman;
  font-size: 12px;
  font-weight: 600;
}
.avatar-cards {
  height:80px;
  width: 80px;
}
.do-you-know-text{
  color: $scorpion;
  font-size: 13px;
  font-weight: 400;
}
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.align-text-justify {
  text-align: justify !important;
}
.text-center-aligned{
  text-align: center !important;
}

.tooltip {
  position: absolute;
}

.correct-pass-key {
  color: $salem;
  font-size: 12px;
}

.wrong-pass-key {
  color: $rust;
  font-size: 12px;
}

.tooltip-inner {
  background: $white;
  border: 1px solid $freespeechred;
  color: $scorpion;
  font-weight: 500;
}

.tooltip.show .arrow::before {
  border-color: $freespeechred transparent $freespeechred transparent;
}

.alert-error {
  font-size: 16px;
  font-weight: 400;
  color: $chileanfire;
}

.alert-success {
  font-size: 15px;
  font-weight: 500;
  color: $onyx;
}
.text-white {
  font-size: 15px;
  font-weight: 500;
  color: $white;
}
.reason-text{
  font-size: 75px;
  font-weight: 900;
  color: $freespeechred;
}
.reason-text-mentor{
  font-size: 40px;
  font-weight: 900;
  color: $freespeechred;
}
.card-header-border {
  background: $white;
  border-bottom: 1px solid $mintcream;
  border-radius: 1px;
  // box-shadow: 0px 1px 8px $whisper;
  color: $scorpion;
  font-weight: 500;
}
.yocard-input[disabled]{
  background: $gainsborolight !important;
  &:hover {
    border-color: $gainsborolight !important;
  }
  &:focus {
    box-shadow: none;
    border-color: $gainsborolight !important;
    color: $onyx;
    background-color: $gainsborolight !important;
  }
}
.yocard-input {
  border: 1px solid $gainsborolight !important;
  background: $white !important;
  // border-bottom: 1px solid $gainsborolight !important;
  border-radius: 5px !important;
  // box-shadow: 0 3px 5px $gainsborolight;
  height: 40px !important;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $freespeechred !important;
    // box-shadow: 0 0 1px $chileanfire;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $freespeechred !important;
  }
}

.yocard-input-dob {
  border: 1px solid $gainsborolight !important;
  background: $white !important;
  border-radius: 5px !important;
  height: 40px !important;
  // width: 110%;
  padding: 0 8px;
  margin: 4px 0;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;
  width: auto !important;
  &:focus {
    box-shadow: none;
    border-color: $freespeechred !important;
    // box-shadow: 0 0 1px $chileanfire;
    outline: $freespeechred !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $freespeechred !important;
  }
}

.dob-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 2px;
}
.inline-dropdown {
  display: inline-block;
  margin: 0 4px;
}
.yocard-input-dob-year{
  background-color: $freespeechred !important;
  color: $chileanfire !important;
}

.yocard-input-oval {
  border: 1px solid $gainsborolight !important;
  background: $white !important;
  // border-bottom: 1px solid $gainsborolight !important;
  border-radius: 40px !important;
  // box-shadow: 0 3px 5px $gainsborolight;
  height: 45px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $freespeechred !important;
    // box-shadow: 0 0 1px $chileanfire;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $freespeechred !important;
  }
}

.yocard-input-oval-search {
  border: 1px solid $gainsborolight !important;
  background: $white !important;
  // border-bottom: 1px solid $gainsborolight !important;
  border-radius: 5px !important;
  // box-shadow: 0 3px 5px $gainsborolight;
  height: 36px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $freespeechred !important;
    // box-shadow: 0 0 1px $chileanfire;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $freespeechred !important;
  }
}

.yocard-input-ref {
  border: 1px solid $gainsborolight !important;
  background: $white !important;
  border-radius: 5px !important;
  height: 38px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $freespeechred !important;
    // box-shadow: 0 0 1px $chileanfire;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $freespeechred !important;
  }
}

.current-icon {
  color: $purpleheart;
  font-size: 10px;
}
.current-text {
  color: $easternblue;
  font-size: 12px;
  font-weight: 700;
}

.yocard-text-area {
  min-height: 80px !important;
  border: 1px solid $gainsborolight !important;
  background-color: $white !important;
  border-radius: 5px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $freespeechred !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $freespeechred !important;
  }
}

.yocard-text-area-desc {
  min-height: 180px !important;
  border: 1px solid $gainsborolight !important;
  background-color: $white !important;
  border-radius: 5px !important;
  padding: 1;
  color: $scorpion;
  font-size: 15px;
  font-weight: 400;

  &:focus {
    box-shadow: none;
    border-color: $freespeechred !important;
    color: $scorpion;
    background-color: $white !important;
  }

  &:hover {
    border: 1px solid $freespeechred !important;
  }
}

.collapse-bg {
  background-color: $cccalendartoday;
  padding: 5px 10px;
  border-radius: 5px;
}

.collapse-icon {
  color: $freespeechred;
}
.modal-header{
  padding: 0 !important;
}
.collapse-header {
  color: $davysgrey;
  font-size: 14px;
  font-weight: 400;
}

.org-header {
  color: $freespeechred;
  font-size: 16px;
  font-weight: 700;
}

.form-collapse-card-header {
  background: $cccalendartoday;
  border: none;
  font-size: 14px;
  font-weight: 500;
  color: $freespeechred;

  &:hover {
    background: $ivory;
  }
}

.mandate-star {
  color: $rust;
  margin: 1px
}
.hr-style{
  color: $davysgrey;
  font-size: 16px;
  font-weight: 600;
}
.hr-profile{
  color: $languidlavender;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  width: 70%;
}

.input-header-text {
  color: $bayofmany;
  font-size: 14px;
  font-weight: 400;
  margin: 3px;
}

.input-card-header-text {
  color: $easternblue;
  font-size: 15px;
  font-weight: 500;
  margin: 3px;
}

.input-checkbox-text-grey {
  color: $onyx;
  font-size: 14px;
  font-weight: 400;
  margin: 3px;
}

.text-lite-grey {
  color: $suvagrey;
  font-size: 13px;
  font-weight: 400;
}

.yocard-chip-box {
  padding: 2px 8px;
  margin: 5px;
  border: 1px solid $peachorange !important;
  border-radius: 35px !important;
  color: $scorpion;
  background-color: $white !important;
  width: auto !important;
}
.dasboard-chip-box {
  padding: 4px;
  margin: 0;
  align-items: center;
  border: 1px solid $geyser !important;
  border-radius: 3px !important;
  color: $dimgray;
  font-size: 14px;
  font-weight: 500;
  background-color: $white !important;
  width: auto !important;
}
.dasboard-chip-box-no-font {
  padding: 4px;
  margin: 0;
  align-items: center;
  border: 1px solid $geyser !important;
  border-radius: 3px !important;
  color: $dimgray;
  background-color: $white !important;
  width: auto !important;
}
.register-chip-box {
  padding: 4px;
  margin: 3px 4px !important;
  align-items: center;
  border: 1px solid $geyser !important;
  border-radius: 3px !important;
  color: $dimgray;
  font-size: 14px;
  font-weight: 500;
  background-color: $white !important;
  width: auto !important;
}

.yocard-chip-text {
  font-size: 14px;
  font-weight: 400;
  color: $onyx;
}

.yocard-chip-icon {
  font-size: 12px;
  color: $freespeechred;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    color: $coral !important;
  }
}

.yocard-chip-plus-icon {
  color: $cerulean;
  cursor: pointer;

  &:hover {
    color: $easternblue !important;
  }
}

.yocard-switch-box {
  color: $easternblue;
  background-color: $white;
  // height: 50px;
  width: auto;
  // max-width: 130px;
  padding: 6px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none !important;
  box-shadow: 0px 1px 1px $peachorange;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background: none !important;
  }
  &:focus:not(:hover) {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-image: linear-gradient(100deg,
    $cccalendartoday 10%,
    $peachorange 80%);
    color: $onyx !important;
  }
}
.yocard-switch-box:focus:not(:hover) {
  background-color: red;
}

.yocard-check-box {
  color: $freespeechred;
  margin-top: 5px;
  outline: 1px solid $freespeechred !important;
  border: 1px solid $white !important;
  box-shadow: 0 0 1px $freespeechred;
  margin-right: 10px;
  width: 13px;
  height: 13px;
  border-radius: 1px !important;
}

.yocard-check-box:checked {
  background-color: $freespeechred !important;
  border: 1px solid $freespeechred !important;
  box-shadow: 0 0 1px $freespeechred;
}

.yocard-check-box:focus,
.label::after,
.label.yocard-check-box:focus,
.yocard-check-box:checked::after,
.yocard-check-box:not(.disabled):not(.disabled):active:focus {
  color: $freespeechred;
  outline: 1px solid $freespeechred !important;
  border: 1px solid $white !important;
  box-shadow: 0 0 1px $freespeechred;
  // box-shadow: 0 0 0 .1rem  $white !important;
}

.yocard-radio-box {
  color: $freespeechred;
  // margin-top: 5px;
  outline: 1px solid $freespeechred !important;
  border: 1px solid $white !important;
  box-shadow: none;//0 0 1px $freespeechred;
  // margin-right: 10px;
  // width: 1px;
  // height: 13px;
  // border-radius: 50% !important;
}

.yocard-radio-box:checked {
  background-color: $freespeechred !important;
  border: 1px solid $freespeechred !important;
  box-shadow: none;
  // box-shadow: 0 0 1px $freespeechred;
}

.yocard-radio-box:focus,
.label::after,
.label.yocard-radio-box:focus,
.yocard-radio-box:checked::after,
.yocard-radio-box:not(.disabled):not(.disabled):active:focus {
  color: $white;
  border: 1px solid $freespeechred !important;
  outline: 1px solid $white !important;
  box-shadow: none;
  // border: 1px solid $white !important;
  // box-shadow: 0 0 0 .1rem  $white !important;
}

.yocard-diffable-untick-text {
  font-size: 14px;
  font-weight: 600;
  color: $valencia;
}

.yocard-colored-text {
  font-size: 14px;
  font-weight: 400;
  color: $valencia;
}

.form-card {
  // padding: 5px 5px;
  border: 1px solid $solitude;
  box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
  border-radius: 5px;
}
.form-card-border {
  // padding: 5px 5px;
  border: 1px solid $hawkesblue;
  box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
  border-radius: 5px;
}

.form-card-lite {
  // padding: 5px 5px;
  border: 1px solid $mintcream;
  border-radius: 50px;
}

.form-card-header {
  background: $cccalendartoday;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: $davysgrey;
}

.form-card-footer {
  background: $white;
  border: none;
}

.form-card-border-none {
  border: none;
}

.form-card-shadow {
  padding: 2px 1px;
  border: 1px solid $whisper;
  box-shadow: 0px 6px 8px rgb(0 55 183 / 8%);
  border-radius: 8px;
}

.form-card-border-wide {
  padding: 5px 8px;
  background-color: $white;
  border: 1px solid $solitude;
  // box-shadow: 0px 6px 1px rgba(131, 132, 132, 0.08);
  border-radius: 10px;
}
.form-card-border-orng {
  padding: 5px 1px;
  background-color: $white;
  box-shadow: none !important;
  border: transparent!important;
  border-radius: 5px;
}
// .yocard-radio-box-active {
//   color: $white !important;
//   background-image: linear-gradient(100deg,
//     $cccalendartoday 20%,
//     $peachorange 90%);
//   // height: 50px;
//   width: auto;
//   // max-width: 130px;
//   padding: 5px 5px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   border: 1px solid $isabelline !important;
//   box-shadow: 0px 1px 1px $chileanfire;
// }

.cursor-pointer {
  cursor: pointer;
}

.loader-campusxcel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $chileanfire;
}

input[type=file]::file-selector-button:hover {
  border: none;
}

input[type=file].avatar {
  background: none !important;
  color: $oslogray;
  font-weight: 500;
}

.form-control:focus {
  box-shadow: none;
}

input[type=file]::file-selector-button {
  background-image: linear-gradient(10deg,
      $peachorange 10%,
      $cccalendartoday 100%);
  border: none !important;
  border-radius: 25px;
  margin: 1px 3px 2px 5px;
  padding: 5px 15px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $darkgreen !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-image: linear-gradient(100deg,
        $cccalendartoday 10%,
        $peachorange 80%);
    color: $onyx !important;
  }
}

// .custom-date-picker::-webkit-calendar-picker-indicator {
//   color: $freespeechred !important;
// }
// .custom-date-picker .react-datepicker-popper .react-datepicker__triangle {
//   color: $freespeechred !important;
// }
// input[type=date]::-webkit-calendar-picker-indicator {
//   color: $freespeechred !important;
// }

// dropdown menu changes

.dropdown-menu.show {
  // border: 1px solid $oasis;
  border: 1px solid $solitude;
  box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
  // box-shadow: 0px 1px 1px $oasis;
  border-radius: 3px;
  font-size: 14px;
}

.dropdown-menu {
  background-image: linear-gradient(130deg,
  $cccalendartoday 80%,
  $peachorange 100%);
  color: $onyx !important;
  font-weight: 600 !important;
  margin: 5px -20px !important;
  padding: 1px 1px;
}
.dropdown-item {
  font-weight: 500;
  color: $scorpion;
  &:hover {
  background-image: linear-gradient(130deg,
  $peachorange 100%,
  $cccalendartoday 10%);
  }
}
.divider-dropdown{
  border: .01rem solid $oasis;
  margin: 8px 5px;
}
.button-icon[disabled] {
  background: transparent;
  color: $dimgray !important;
}

.button-icon {
  background: transparent;
  border: none;
  color: $rust !important;
  cursor: pointer;

  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $cccalendartoday !important;
  }

  &:hover {
    background-color: $cccalendartoday;
    color: $firebush !important;
  }
}
.button-icon-active {
  color: $firebush !important;
}
.button-icon-inactive {
  color: $bombay !important;
}
// .cert-image {
//   height:160px;
//   width:130px;
// }
// .cert-image:hover {
//   height:260px;
//   width:230px;
// }
.button-icon-small-pdf {
  color: $christalle !important;
}
.button-icon-small[disabled] {
  background: transparent;
  color: $dimgray !important;
}

.button-icon-small {
  background: transparent;
  border: none;
  color: $freespeechred !important;
  cursor: pointer;
  padding: 0;
  margin: 0;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $cccalendartoday !important;
  }

  &:hover {
    background-color: $cccalendartoday;
    color: $chileanfire !important;
  }
}

.video-button-image{
  max-width: 140px;
}
.quest-menu-icon {
  color: $onyx;
}

.quest-menu-icon-size-sm {
  color: $firebush;
  font-size: 12px;
}

.yocard-dash-hover-click {
  cursor: pointer;
  &:hover {
    background-color: $mintcream !important;
  }
}

.yocard-header-with-bg {
  color: $onyx !important;
  background-color: $oasis !important;
  font-size: 13px;
  font-weight: 600;
  margin: 5px 4px !important;
  padding: 5px 10px;
}
.yocard-label-bg {
  color: $onyx !important;
  background-color: $ivory !important;
  font-size: 12px;
  font-weight: 700;
  margin: 5px 4px !important;
  padding: 5px 10px;
}

.my-1 {
  margin: 5px 0;
}
.my-2 {
  margin: 10px 0;
}
.mx-1 {
  margin: 0 5px;
}

.mx-2 {
  margin: 0 10px;
}

.mx-3 {
  margin: 0 1rem;
}

.mx-4 {
  margin: 0 2rem;
}

.mx-5 {
  margin: 0 3rem;
}

.ml {
  margin-left: .5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.pl-1 {
  padding-left: 1rem;
}
.mp {
  padding: .3rem;
}
.mp-01 {
  padding: .3rem .7rem;
}
.mp-1 {
  padding: 1rem;
}

.mp-2 {
  padding: 2rem;
}

.mptb {
  padding: .3rem 0;
}

.mptb-1 {
  padding: 1rem 0;
}

.mptb-2 {
  padding: 2rem 0 !important;
}
.mptb-3 {
  padding: 3rem 0 !important;
}
.ptop-1 {
  padding-top: 1rem;
}
.ptop-2 {
  padding-top: 1.5rem;
}
.pb-1 {
  padding-bottom: 10px;
}
.mr {
  margin-right: .5rem;
}
.mr-1 {
  margin-right: 1rem;
}

.mr-2 {
  margin-right: 2rem;
}

.mb {
  margin-bottom: 5px;
}
.mb-1 {
  margin-bottom: 10px;
}

.mt {
  margin-top: 5px;
}

.mt-1 {
  margin-top: 10px !important;
}

.mlr-1 {
  padding: 0 1rem;
}
.mlr-2 {
  padding: 0 2rem;
}
.margin-zero {
  margin: 0 !important;
}
.padding-zero {
  padding: 0 !important;
}

/* Default styles */
.yocard-media {
  font-size: 16px;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.nav-tabs {
  border-bottom: none !important;
}

/* Adjust for smaller screens */
@media only screen and (max-width: 576px) {
  .yocard-media {
    font-size: 14px;
    max-width: 576px;
  }

  // .yocard-radio-box {
  //   width: 70px !important;
  // }

  // .yocard-radio-box-active {
  //   width: 70px !important;
  // }

  .display-data-none {
    display: none;
  }

  .margin-top-bottom {
    margin: .1rem 0 3rem 0 !important;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .margin-top-more {
    margin-top: 20px;
  }

  .nav-tabs {
    border-bottom: none !important;
  }
}

/* Adjust for even smaller screens */
@media only screen and (max-width: 576px) {
  .yocard-media {
    font-size: 12px;
    max-width: 576px;
  }

  // .yocard-radio-box {
  //   width: 70px !important;
  // }

  // .yocard-radio-box-active {
  //   width: 70px !important;
  // }

  .display-data-none {
    display: none;
  }

  .margin-top-bottom {
    margin: .1rem 0 3rem 0 !important;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .margin-top-more {
    margin-top: 2.6rem !important;
  }

  .nav-tabs {
    border-bottom: none !important;
  }
  .yocard-header-with-bg {
    font-size: 12px;
    margin: 5px 1px !important;
    padding: 0;
  }
  .yocard-label-bg {
    font-size: 12px;
    margin: 5px 1px !important;
    padding: 0;
  }
}


// .yocard-media {
//   width: 100%;
//   max-width: 1440px; /* Set the maximum width */
//   margin: 0 auto; /* Center the element horizontally */
// }

// /* Set the width for mobile screens */
// @media only screen and (max-width: 576px) {
//   .yocard-media {
//     max-width: 540px; /* Adjust the maximum width */
//   }
// }

// /* Set the width for iPad screens */
// @media only screen and (min-width: 601px) and (max-width: 1024px) {
//   .yocard-media {
//     max-width: 576px; /* Adjust the maximum width */
//   }
// }

// /* Set the width for laptop screens */
// @media only screen and (min-width: 1025px) and (max-width: 1440px) {
//   .yocard-media {
//     max-width: 1000px; /* Adjust the maximum width */
//   }
// }

// /* Set the width for desktop screens */
// @media only screen and (min-width: 1441px) {
//   .yocard-media {
//     max-width: 1440px; /* Adjust the maximum width */
//   }
// }