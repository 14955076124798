@import '../../../styles/scss/index.scss';

.yoCard-bg {
  // background: linear-gradient(125deg, $ivory, $alto);
  // min-height: 90vh !important;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 100px;
}
.yo-home-graph {
  height: 450px !important;
  width: 450px !important;
}
.text-graph-header {
  color: $rust;
}
.clients-yoCard-bg{
  background: linear-gradient(125deg, $seashell, $cccalendartoday);
  align-items: center;
  justify-content: center;
  // margin-top: 50px;
  padding: 30px;
}
.why-yoCard-bg {
  background: linear-gradient(125deg, $ivory, $cccalendartoday);
  // min-height: 110vh !important;
  align-items: center;
  justify-content: center;
}

.about-yoCard-bg {
  // min-height: 110vh !important;
  // background: linear-gradient(160deg, $cccalendartoday, $seashell);
  align-items: center;
  justify-content: center;
  // margin-top: 50px;
  padding-top: 60px !important;
}
.about-wds-bg {
  // min-height: 110vh !important;
  background: linear-gradient(160deg, $cccalendartoday, $ivory);
  align-items: center;
  justify-content: center;
  // margin-top: 50px;
  padding: 50px 5px;
}

.steps-reg-yoCard-bg {
  background: $white;
  // min-height: 110vh !important;
  align-items: center;
  justify-content: center;
  padding-top: 50px !important;
}

.testimonial-yoCard-bg{
  background: linear-gradient(125deg, $ivory, $cccalendartoday);
  // min-height: 110vh !important;
  align-items: center;
  justify-content: center;
  margin-top: 50px !important;
}

.why-card {
  padding: 20px 10px;
  margin-bottom: 30px;
  min-height: 300px !important;
  border: 1px solid $whisper;
  box-shadow: 0px 6px 8px rgb(0 55 183 / 8%);
  border-radius: 8px;
}

.why-card-header {
  border-bottom: none !important;
  background: transparent !important;
}

.squares {
  animation: move-left-right 1s infinite;
  background: $ivory;
  background: -webkit-linear-gradient(0deg, $ivory 0%, $chetwodeblue 100%);
  background: -o-linear-gradient(0deg, $ivory 0%, $chetwodeblue 100%);
  background: -moz-linear-gradient(0deg, $ivory 0%, $chetwodeblue 100%);
  background: linear-gradient(0deg, $ivory 0%, $chetwodeblue 100%);
  position: absolute;
  transition: 0.5s ease-out;
  overflow: hidden;
  border-radius: 20%;
}

.squares.square1 {
  animation: move-left-right 3s infinite !important;
  height: 250px;
  width: 250px;
  opacity: 0.1;
  // left: 32%;
  // bottom: 29%;
}

.squares.square2 {
  animation: move-left-right 3s infinite;
  width: 300px;
  height: 300px;
  right: -0%;
  bottom: 0%;
  opacity: 0.1;
}

.squares.square3 {
  animation: move-left-right 3s infinite;
  width: 250px;
  height: 250px;
  left: -5%;
  bottom: 10%;
  opacity: 0.1;
}

.text-intro-header {
  color: $freespeechred;
  font-weight: 700;
}

.text-intro-sub-header {
  color: $freespeechred;
  font-weight: 700;
}
.text-intro-sub-header-grey {
  color: $raven;
  font-weight: 700;
  font-size: 22px;
}

.text-intro {
  color: $raven;
  font-size: 20px;
  font-weight: 500;
}
.text-AI{
  color: $outrageousorange;
  font-size: 22px;
  font-weight: 600;
}
.text-why {
  color: $raven;
  font-size: 16px;
  font-weight: 500;
}

.text-about {
  color: $raven;
  font-size: 22px !important;
  font-weight: 500;
}
.text-about-cards {
  color: $raven;
  font-size: 16px !important;
  font-weight: 500;
}

.text-why-card {
  color: $raven;
  font-size: 15px;
  font-weight: 500;
}

.border-count-why {
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 100px;
  outline: none;
  background: $dimgray;
  color: white;
  cursor: pointer;
  font-size: 25px;
  font-weight: 700;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.button-next-prev{
  background-color: transparent;
  border: 1px solid $outrageousorange;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 2px;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    border: 1px solid $outrageousorange !important;
    background-color: $cccalendartoday !important;
  }
  &:hover {
    border: 1px solid $outrageousorange;
    background-color: $cccalendartoday;
  }
}
.next-prev-icon{
  color: $outrageousorange;
}
.quote-icon{
  color: $outrageousorange;
  font-size: 14px;
  margin: 5px;
}
.border-yoCard{
  border: 1px solid $oasis;
  border-radius: 15px;
}
.intro-btn-filled {
  background-image: linear-gradient(10deg,
      $burntsienna 10%,
      $cccalendartoday 100%);
  border: none !important;
  border-radius: 10px;
  margin: 1px 3px 2px 45px;
  padding: 15px 55px;
  font-size: 20px !important;
  font-weight: 500 !important;
  color: $codgray !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    background-image: linear-gradient(100deg,
        $cccalendartoday 10%,
        $coral 80%);
    color: $onyx !important;
  }
}
.process-image{
  // width:300px;
  // height: auto;
  border-radius: 1px !important;
}
.process-card-align{
  min-height: 390px;
}

@media screen and (max-width: 576px) {
  .squares {
    display: none;
  }
}

.footer {
  clear: both;
  position: relative;
  bottom: 0;
  // padding: 10px;
  background: $greenvogue;
}

.text-footer-header{
  color: $white;
  font-size: 20px;
  font-weight: 500;
}
.text-footer-know-yocard{
  color: $white;
  font-size: 28px;
  font-weight: 700;
}
.text-footer-desc{
  color: $white;
  font-size: 14px;
  font-weight: 400;
}
.btn-footer-icon{
  border: 1px solid $white;
  border-radius: 5px;
  padding: 3px 5px;
  margin: 2px;
  min-width: 40px !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &:hover {
    border: 1px solid $pancho;
  }
}
.footer-wds-icon{
  color: $white;
}
.copyright{
  color: $white;
  font-weight: 300;
}
.text-copyright-mail{
  color: $white;
  font-weight: 400;
  &:hover {
    color: $whisper;
  }
}
.text-footer-abou-count{
  font-size: 35px;
  color: $darkturquoise;
  font-weight: 800;
}
.text-footer-abou-title{
  font-size: 25px;
  color: $white;
  font-weight: 700;
}
.text-footer-know-campus{
  color: $white;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.contact-wds-bg {
  background: $white;
  padding: 5em 0;
  align-items: center;
  justify-content: center;
}

.text-email{
  color: $outrageousorange;
  text-decoration: none;
}