@import './../../styles/scss/index.scss';
table {
    // width: 100%;
    border-spacing: 0;
    background-color: #FFFFFF;
    font-size: 14px;
    overflow-x: auto;
    display: block;
}

thead th {
    color: #7A7A7A;
    text-align: justify !important;
    background-color: #F9FAFC;
}

thead th,
tbody td {
    padding: 15px 10px;
    border: 0;
    text-align: left;
    color: #7A7A7A;
    border-bottom: 1px solid #E7E7E7;
}

tbody td:first-child {
    border-left: 4px solid transparent;
    transition: all ease 0.3s;
}

tbody tr:last-child td {
    border-bottom: 0;
}

tbody tr:hover {
    background-color: #F7F9FC;
}

tbody tr:hover td:first-child {
    border-left-color: #7e1c06;
}

td.status {
    text-align: start;
    width: 20% !important;
}
td.filename {
    text-align: start;
    width: 10% !important;
}
td.action {
    text-align: start;
    width: 20% !important;
}
td.modified {
    text-align: start;
    width: 35% !important;
}
td.size {
    text-align: start;
    width: 15% !important;
}
.truncate-on-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px; /* Adjust this width as needed */
  }
  
  .truncate-on-overflow:hover {
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
  }
// .first-column-width{
// white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   word-wrap: break-word;
//   width: 25em; 
// }
td.status span {
    font-size: 12px;
    font-weight: 600;
    border-radius: 3px;
    padding: 4px 6px;    
}

td.status span.approved {
    color: #FFFFFF !important;
    background-color: #03b751 !important;
}

td.status span.decline {
    color: #FFFFFF;
    background-color: #db1f12;
}

td.status span.pending {
    color: #132D4A;
    background-color: #EBF0F5;
}

#toggleView:checked~table thead {
    display: none;
}

#toggleView:checked~table tbody {
    display: flex;
    flex-wrap: wrap;
    padding: 4px;
}

#toggleView:checked~table tbody tr,
#toggleView:checked~table tbody td {
    display: block;
    border: 0;
}

#toggleView:checked~table tbody td {
    padding: 4px;
    font-weight: 400;
    color: #132D4A;
}

#toggleView:checked~table tbody td:before {
    content: attr(data-name);
    width: 100px;
    display: inline-block;
    text-transform: capitalize;
    font-weight: normal;
}

#toggleView:checked~table tbody td.status {
    position: absolute;
    top: 4px;
    right: 4px;
}

#toggleView:checked~table tbody tr {
    position: relative;
    width: calc(50% - 8px);
    border: 1px solid $solitude;
    box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
    border-radius: 5px;
    padding: 8px;
    margin: 4px;
}

@media screen and (max-width: 576px) {
    .align-card-data {
        margin-top: 80px;
    }

    .align-view-to-row {
        margin-top: 10px;
    }

    #toggleView:checked~table tbody tr {
        position: relative;
        width: calc(100% - 8px);
        border: 1px solid $hawkesblue;
        box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
        border-radius: 5px;
        padding: 8px;
        margin: 4px;
    }
}

.paginate-number-page {
    width: 25px;
    height: 25px;
    border: 2px solid $rust;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: $rust;
}