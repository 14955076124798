@import '../../../styles/scss/index.scss';

.login-align-vertical {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 2rem;
}
.login-card-home {
    position: relative;
    width: 1000px;
    height: auto;
    background-color: $white;
    border: transparent;
    // box-shadow: 0px 6px 8px rgb(0 55 183 / 8%);
    border-radius: 8px;
}
.login-card {
    position: relative;
    width: 1000px;
    height: auto;
    background-color: $white;
    border: 1px solid $whisper;
    box-shadow: 0px 6px 8px rgb(0 55 183 / 8%);
    border-radius: 8px;
}
.login-card-header-footer{
    background-color: $white;
    border: none;
}
.login-card-image-bg{
    background-color: $mintcream;
    border: none;
}

.vertical-align{
    border-left: 1px solid $isabelline;
}
.sign-up-header{
    color: $raven;
    font-size: 24px;
    font-weight: 500;
}
.login-sign-up-text-header{
    color: $lightsalmon;
    font-size: 22px;
    font-weight: 400;
}
.already-text{
    color: $suvagrey;
    font-size: 15px;
    font-weight: 500;
}
.forgot-password-text{
    color: $suvagrey;
    font-size: 15px;
    font-weight: 400;
    &:hover {
        color: $freespeechred;
      }
}
.button-already {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  
    &:hover {
      background: none;
      border: none;
      color: $bahamablue;
    }
  
    &:focus,
    &:active {
      outline: none;
      border: none;
      box-shadow: none;
      color: $bahamablue !important;
      background-color: transparent !important;
    }
  }
  
.text-login-already{
    color: $freespeechred;
    font-weight: 400;
    padding-bottom: 2px;
}
.yocard-font-icon{
    color: $outrageousorange !important;
}
.pwd-eye-icon {
    color: $lightsalmon !important;
    margin-right: 5px;
    font-size: .95em;
    margin-bottom: 1px !important;
}
.pwd-eye-close-icon {
    color: $bombay !important;
    margin-right: 5px;
    font-size: .95em;
    margin-bottom: 1px !important;
}
.login-sign-up-text{
    color: $scorpion;
    font-size: 14px;
    font-weight: 400;
}
.login-sign-up-icon{
    color: $eucalyptus;
}
.yocard-gender-text{
    color: $davysgrey;
    font-size: 13px;
    font-weight: 500;
}
.image-top-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    margin: -70px;
    z-index: -1 !important;
    background-image: url('./../../../../public/images/login-register/dots.png');
    background-size: cover !important;
}

.image-bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 150px;
    height: 150px;
    margin: -80px;
    z-index: -1;
    background: linear-gradient(125deg, $shadessalmon, $outrageousorange);
    background-size: cover;
}
.text-whatsapp {
    font-size: 12px !important;
    color: $salem;
  }
@media screen and (max-width: 576px) {
    .vertical-align{
        border: none;
        padding-top: 50px;
    }

}
