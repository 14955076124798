@import "../../../styles/scss/index.scss";

.navbar-printer {
  background: $spacecadet;
  // background-image: linear-gradient(90deg,
  //     $chileanfire 10%,
  //     $jacksonspurple 70%);
  font-size: 1.2rem;
  position: fixed !important;
  top: 0;
  margin: 0 !important;
  padding: 5px 10px;
  width: 100%;
  // width: calc(100% - 300px);
  z-index: 1030;
  box-shadow: 0 0 1px $alto;
}

.printer-grey-icon {
  font-size: 16px !important;
  font-weight: 700;
  color: $davysgrey;
}
.printer-grey-text {
  font-size: 12px !important;
  font-weight: 700;
  color: $davysgrey;
}
.printer-user-header-grey-text {
  font-size: 14px !important;
  font-weight: 500;
  color: $davysgrey;
}
.printer-user-subheader-grey-text {
  font-size: 12px !important;
  font-weight: 500;
  color: $davysgrey;
}
.white-sadm-text {
  font-size: 13px;
  color: $white;
  font-weight: 500;
}

.navbar-printer-icon {
  font-size: 1.5rem !important;
  color: $white;
  padding: 15px 10px !important;
}

.yo-user-icon {
  height: 40px;
  width: 40px;
}

.navbar-printer-close-btn,
.navbar-printer-open-btn {
  // font-size: 24px;
  transition: opacity 0.3s ease;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.navbar-printer-icon {
  font-size: 2.4rem;
  color: $white;
}

.route-margin {
  margin: 6rem 0px !important;
  padding: 2px 1.2rem;
}

.menu-link-notification {
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 1px 5px;
}

.menu-link-notification:hover {
  background-color: $cccalendartoday;
  padding: 1px 5px;
}

.border-bottom-nav {
  border: .01em solid $pancho !important;
  margin: 1px !important;
}

.menu-link-notification:hover .menu-text-notification {
  text-decoration: underline;
}

.yocard-notification-txt {
  color: $white !important;
  font-weight: 600;
}
.yocard-header-icon {
  color: $white !important;
  font-size: 16px;
  margin: 2px;
  &:hover {
      color: $rajah !important;
  }
}

.yocard-notification-icon {
  color: $davysgrey !important;
  font-weight: 600;
}

.printer-footer-visible {
  display: none;
}
li{
  list-style: none;
}
@media only screen and (max-width: 576px) {
  .footer-visible {
    display: none; // Change to block for hover effect bottom
  }
  .yocard-display-device {
    display: none;
  }

  .yocard-display-device-mobile {
    display: block;
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: $jacksonspurple;
    margin-top: 40px;
    padding: 10px 0;
    display: none; // this is required if u want to put visibilty only on scroll
  }

  li {
    border-bottom: none;
    width: 100% !important;
    list-style: none;
  }
}