@import "../../../styles/scss/index.scss";

.printercard-dashboard {
    width: 100%;
    margin: 0 auto;
    box-shadow: 5px 5px 10px rgba(22, 24, 26, 0.07);
    border: 1px solid $isabelline;
    border-radius: 10px;
    background-color: $white;
    min-height: 355px;
}
.printer-card-status-box {
    border: none;
    color: $onyx;
    font-size: 13px;
    font-weight: 600;
    background-color: $oasis;
    border-radius: 2px;
    padding: 2px 10px;
}

.printercard-dashboard-header {
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: $davysgrey;
}
.print-completed {
    color: $salem;
    font-size: 14px;
  }
.printercard-dash-icon {
    font-size: 22px;
    font-weight: 600;
    color: $slateblue;
}

.printercard-card-dash-header {
    font-size: 17px;
    font-weight: 600;
    color: $valencia;
}

.printer-circle_container {
    width: 100px !important;
    height: 100px !important;
    margin: 0;
    padding: 0;
}

.printer-circle_main {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid $jazzberryjam !important;
    margin: 0;
    padding: 0;
}

.printer-circle_total {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid $cerulean;
    margin: 0;
    padding: 0;
}

.printer-circle_text_container {
    width: 70%;
    height: 70%;
    max-width: 70%;
    max-height: 70%;
    margin: 0;
    padding: 0;
    position: relative;
    left: 15%;
    top: 15%;
    transform-style: preserve-3d;
}

.printer-circle_text {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px !important;
    font-weight: 400 !important;
    color: $onyx !important;
}

.dash-all-header-text {
    font-size: 15px;
    font-weight: 600;
    color: $onyx;
}

.printer-adjust-doughnut {
    height: 300px;
    width: auto;
}
.printer-adjust-line {
    height: 350px;
    width: auto;
}
.printer-horizontal-graph{
    height: auto;
    width: auto;
}
@media only screen and (max-width: 576px) {
    .printer-margin-top-bottom {
        margin-top: 50px;
    }
}