$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
// h1 {
//   font-size: 75px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
// h2 {
//   font-size: 55px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
// h3 {
//   font-size: 40px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
// h4 {
//   font-size: 30px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
// h5 {
//   font-size: 24px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
// h6 {
//   font-size: 20px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
// p {
//   font-size: 17px;
//   color: $onyx;
//   font-family: "Poppins", sans-serif;
// }
// span {
//   font-size: 15px;
//   color: $dimgray;
//   font-family: "Poppins", sans-serif;
// }
.btn-secondary{
  --bs-btn-hover-bg: 'none' !important;
}
.yocard-desc-abt-job-jb-card-img {
  height: 150px;
  width: auto;
  max-height: 150px !important;
  object-fit: contain;
  align-self: center;
  padding: 5px;
}
.yocard-button-oval {
  background-color: $freespeechred !important;
  border: transparent !important;
  border-radius: 3px;
  padding: 5px 10px;
  // height: 35px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $white !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    // background-color: $white !important;
  }
  &:hover {
    background-image: linear-gradient(100deg,
    $bittersweet 10%,
    $freespeechred 80%);
    color: $white !important;
  }
}
.yocard-dash-icon{
  color: $white !important;
}
.yocard-button-oval[disabled] {
  background: transparent;
  color: $white !important;
  border: transparent !important;
}
.yocard-button-plain {
  background-color: $white !important;
  border: 1px solid  $freespeechred !important;
  border-radius: 5px;
  // margin: 1px 3px 2px 5px;
  padding: 3px 10px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: $darkgreen !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    box-shadow: none !important;
    background-color: $white !important;
  }
  &:hover {
    background-image: linear-gradient(100deg,
    $cccalendartoday 10%,
    $peachorange 80%);
    color: $onyx !important;
  }
}
.yocard-button-link {
  background-color: transparent !important;
  border: transparent !important;
  margin: 1px 3px 2px 5px;
  padding: 5px 15px;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: $onyx !important;
  cursor: pointer;
  &:focus {
    outline: 0;
    color: $freespeechred !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
  &:hover {
    outline: 0;
    color: $freespeechred !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}