@import "../../../styles/scss/index.scss";

.stadmin-margin-top-bottom {
    margin-top: 50px;
}
.dash-edit-icon {
    color: $freespeechred;
    font-size: 13px;
}

.stadmincard-dashboard {
    width: 100%;
    margin: 0 auto;
    box-shadow: 5px 5px 10px rgba(22, 24, 26, 0.07);
    border: 1px solid $isabelline;
    border-radius: 10px;
    background-color: $white;
    min-height: 355px;
}

.stadmincard-dashboard-header {
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: $davysgrey;
}

.stadmincard-dash-icon {
    font-size: 22px;
    font-weight: 600;
    color: $slateblue;
}

.stadmincard-card-dash-header {
    font-size: 17px;
    font-weight: 600;
    color: $valencia;
}

.stadmin-circle_container {
    width: 150px;
    height: 150px;
    margin: 0;
    padding: 0;
}

.stadmin-circle_main {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 13px solid $easternblue;
    margin: 0;
    padding: 0;
}

.stadmin-circle_text_container {
    width: 70%;
    height: 70%;
    max-width: 70%;
    max-height: 70%;
    margin: 0;
    padding: 0;
    position: relative;
    left: 15%;
    top: 15%;
    transform-style: preserve-3d;
}

.stadmin-circle_text {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: 600;
    color: $outrageousorange;
}

.dash-all-header-text {
    font-size: 15px;
    font-weight: 600;
    color: $onyx;
}

.sadmin-adjust-doughnut {
    height: 300px;
    width: auto;
}
.stadmin-adjust-line {
    height: 350px;
    width: auto;
}
.stadmin-horizontal-graph{
    height: auto;
    width: auto;
}
@media only screen and (max-width: 576px) {
    .stadmin-margin-top-bottom {
        margin-top: 50px;
    }
}
.td-text{
    font-size: 14px !important;
    font-weight: 400;
    color: $onyx;
}
.td-text-lite-grey {
    color: $suvagrey;
    font-size: 12px;
    font-weight: 400;
}
.td-text-highlight {
    color: $freespeechred;
    font-size: 11px;
    font-weight: 500;
}
.td-text-status{
    font-size: 13px !important;
    font-weight: 400;
    color: $onyx;
}