@import '../../../../styles/scss/index.scss';

.user-sadmin-row-profile-tab-mobile {
    display: none;
}

.user-sadmin-row-profile-tab {
    display: block;
}

.user-sadmin-column-profile-tab-left {
    float: left;
}
.user-sadmin-column-profile-tab-right {
    float: right;
  }
.user-sadmin-profile-nav-tabs {
    color: $bombay !important;
    background-color: $mintcream !important;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 1px 10px !important;
    padding: 1px 15px;
}

.user-sadmin-profile-nav-tabs.active {
    color: $freespeechred !important;
    background: $white !important;
    letter-spacing: 1px;
    border: none;
    border-bottom: 1px solid $isabelline !important;
}

.super-user-name {
    color: $freespeechred !important;
    font-size: 16px;
    font-weight: 600;
}
.box-user-type {
    color: $onyx !important;
    font-size: 15px;
    font-weight: 500;
    background-color: $oasis;
    padding: 0 3px;
}

.user-sadmin-tab-profile-icon {
    font-size: 17px;
    margin-right: 10px;
}
.user-sadmin-card-body-atbtme{
    min-height: 150px !important;
}

@media screen and (max-width: 576px) {
    .user-sadmin-row-profile-tab-mobile {
        display: block;
        // margin-top: 10px;
    }

    .user-sadmin-row-profile-tab {
        display: none;
    }

    .user-sadmin-tab-profile-icon {
        font-size: 20px;
        margin-right: 10px;
    }
    .user-sadmin-profile-tab {
        border: none !important;
    }
    .user-sadmin-profile-nav-tabs {
        letter-spacing: 1px;
        font-size: 14px;
        font-weight: 600;
        margin: 1px 1px !important;
        padding: 1px 15px;
        border: none !important;
    }
    .user-sadmin-profile-nav-tabs.active {
        color: $freespeechred !important;
        background: $white !important;
        letter-spacing: 1px;
        border: none !important;
    }
}