$white: #ffffff;
$onyx: #383838;
$crusta: #f98355;
$darkturquoise: #02c9d7;
$raisinblack: #212121;
$black: #000000;
$gainsboro: #d5c8c8;
$isabelline: #f6eeeb;
$gainsborolight: #DDE0E4;
$lemonchiffon: #fbf4cd;
$tangerineyellow: #fece00;
$shandy: #ffe172;
$cultured: #f4f5f7;
$davysgrey: #595555;
$suvagrey: #959191;
$silver: #BFBFBF;
$orange: #dc6d05;
$lightcayan: #D4F9F7;
$violentviolet: #260751;
$blackcurrant: #1f0248;
$aliceblue: #F2F6F8;
$aqua: #02FAEA;
$robineggblue: #16C8BC;
$chetwodeblue: #676ea3;
$sinbad: #A6D2D0;
$bayofmany: #373B5A;
$darkcerulean: #11407e;
$endeavour: #295A9B;
$salem: #0A8242;
$eucalyptus: #3B9B68;
$emerald: #5AE38C;
$lightskyblue: #79D6FB;
$mintcream: #FAFCFB;
$pattensblue: #E9EDEF;
$bahamablue: #275F83;
$rust: #C13B06;
$coral: #FF7F50;
$freespeechmagneta: #E14ECA;
$magnolia: #f7f4f9;
$whitelilac: #eeddfb;
$bluediamond: #472B6E;
$purpleheart: #7c43cb;
$amethyst: #9366d2;
$greenvogue: #11404D;
$easternblue: #187C98;
$tara: #DDF7DE;
$tusk: #DCEAB5;
$dodgerblue: #1D8CF8;
$brightturquoise: #00F2C3;
$shadessalmon: #FF8D72;
$resolutionblue: #344675;
$whisper: #E6E6E6;
$perano: #AABCE0;
$christalle: #26035A;
$zumthor: #CFD4D4;
$zircon: #DBE1E1;
$blackpearl: #091d29;
$darkgreen: #02302B;
$tuliptree: #EBAB41;
$seashell: #FFF8F2;
$ivory: #FAFAF1;
$darkgray: #aaa8a8;
$scorpion: #575656;
$bombay: #b3b8c2;
$raven: #6e7687;
$oslogray: #8c8f95;
$alto: #e0e0e0;
$cerulean: #10a7c1;
$screamingreen: #90fe6e;
$alabaster: #f9f9f9;
$geyser: #dee2e6;
$codgray: #181717;
$gossip:#92E86B;
$feijoa:#A8ED89;
$solitude:#E1EDF8;
$bilabo:#93C47D;
$fern:#5CC45C;
$pastelgreen:#76E376;
$oasis:#FFF1CA;
$maverick:#D5A6BD;
$hopbush:#C27BA0;
$creambrulee:#f2e0aa;
$nobel:#999999;
$deeppink:#F21984;
$jazzberryjam:#941453;
$tenne:#B45F06;
$roman:#E06666;
$beautybush:#EFB1B1;
$valencia:#DB4D4D;
$burntsienna:#ED7547;
$rajah:#F6B26B;
$peachorange:#F9CB9C;
$pancho:#E1BF9D;
$outrageousorange:#F05E25;
$lightsalmon:#FA9D79;
$grey:#807F7F;
$dimgray:#616161;
$cccalendartoday: #fbfbf9;
$trendgrey: #DFEABA;
$magnolia:#ebe6f3;
$hawkesblue:#D2DCE9;
$firebush: #E2A249;
$trumpetflower: #e17f55;
$spacecadet: #2F2A60;
$languidlavender: #CECBE8;
$bluebell: #9D97D1;
$slateblue: #6C63BA;
$darkslateblue: #484093;
$jacksonspurple: #2E378E;
$chileanfire: #DD5637;
$freespeechred: #880000; //BG and main color
$bittersweet:#c62c2c; //lite main color