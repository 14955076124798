@import '../../../../styles/scss/index.scss';

.accounts-header{
    font-weight: 800;
    color: $freespeechred;
    font-size: 22px;
}
.accounts-header-bal{
    font-weight: 800;
    color: $salem;
    font-size: 22px;
}
.accounts-header-black{
    font-weight: 500;
    color: $onyx;
}
.credit-tp-text{
    font-weight: 500;
    font-size: 15px;
    color: $onyx;
}
.print-status-pending{
    font-weight: 700;
    color: $spacecadet;
    font-size: 13px;
}
.print-status-completed{
    font-weight: 700;
    color: $salem;
    font-size: 13px;
}
.td-text-lite-main {
    color: $bayofmany;
    font-size: 12px;
    font-weight: 400;
}
.td-text-lite-main-bold {
    color: $freespeechred;
    font-size: 12px;
    font-weight: 600;
}
.accounts-card-icon {
    color: $bayofmany;
    font-size: 13px;
}
.accounts-card-icon-lite {
    color: $davysgrey;
    font-size: 10px;
}
.stopr-circle_container {
    width: 100px !important;
    height: 100px !important;
    margin: 0;
    padding: 0;
}

.stopr-circle_main {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid $freespeechred !important;
    margin: 0;
    padding: 0;
}

.stopr-circle_total {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid $cerulean;
    margin: 0;
    padding: 0;
}

.stopr-circle_text_container {
    width: 70%;
    height: 70%;
    max-width: 70%;
    max-height: 70%;
    margin: 0;
    padding: 0;
    position: relative;
    left: 15%;
    top: 15%;
    transform-style: preserve-3d;
}

.stopr-circle_text {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $onyx !important;
}
.status-completed {
    color: $white !important;
    background-color: $salem !important;
    font-size: 13px;
    font-weight: 600;
    margin: 5px 0px !important;
    padding: 2px 5px;
}
.status-pending {
    color: $white !important;
    background-color: $outrageousorange !important;
    font-size: 13px;
    font-weight: 600;
    margin: 5px 0px !important;
    padding: 2px 5px;
}
.hr-accounts-det{
    color: $languidlavender;
    margin: 2px 10px;
    font-size: 16px;
    font-weight: 600;
}
.add-txn-icon{
    font-size: 24px;
    color: $freespeechred;
}
.payment-mode-icon{
    font-size: 14px;
    color: $lightsalmon;
}

.card-container {
    position: relative;
    display: inline-block;
    background-color: transparent;
    border: none;
  }
  
  .hover-icon-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(7, 7, 7, 0.5); /* Adjust opacity and color as needed */
    transition: opacity 0.3s ease;
  }
  
  .hover-icon-container:hover {
    opacity: 0.7; /* Show on hover */
  }
  
  .hover-icon {
    color: white; /* Icon color */
    font-size: 24px; /* Adjust size as needed */
  }
.cour-image{
    min-width: 800px !important;
    min-height: 800px !important;
}
@media only screen and (max-width: 576px) {
    .cour-image{
        min-width: 100% !important;
        min-height: 100% !important;
    }
}