@import '../../../styles/scss/index.scss';

.login-align-vertical-register {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 2rem;
}
// .term-use{
//     color: $outrageousorange !important;
//     font-style: italic;
//     cursor: pointer;
// }
.align-text-center {
    text-align: center !important;
}
.adm-bg {
    background-color: $cccalendartoday;
    padding: 10px 10px;
}
.form-register-border {
    border: 1px solid $aliceblue;
    border-radius: 3px;
    padding: 8px 4px;
}
.button-term-use {
    background: transparent;
    border: none;
    color: $freespeechred !important;
    font-size: 13px;
    font-style: italic;
    padding:0;
    margin-bottom: 2px;
    cursor: pointer;
  
    &:focus {
      outline: 0;
      box-shadow: none !important;
      background-color: transparent !important;
    }
  
    &:hover {
      background-color: transparent;
      color: $bittersweet !important;
    }
  }
// .element.style{
//     line-height: -10px !important;
//     padding: 10px;
// }
.login-card-register {
    position: relative;
    width: 1000px;
    height: auto;
    background-color: #fff;
    border: 1px solid $mintcream !important;
    box-shadow: 0px 1px 1px rgb(0 55 183 / 8%);
    border-radius: 8px;
}
.login-card-header-footer-register{
    background-color: $white;
    border: none;
}
.vertical-align-register{
    border-left: 1px solid $isabelline;
}
.sign-up-header-register{
    color: $bahamablue;
    font-size: 24px;
    font-weight: 400;
}
.sign-up-header-register-mentor{
    color: $bahamablue;
    font-size: 22px;
    font-weight: 400;
}
.login-sign-up-text-header-register{
    color: $lightsalmon;
    font-size: 22px;
    font-weight: 400;
}
.already-text{
    color: $suvagrey;
    font-size: 15px;
    font-weight: 500;
}
.button-already {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  
    &:hover {
      background: none;
      border: none;
      color: $onyx;
    }
  
    &:focus,
    &:active {
      outline: none;
      border: none;
      box-shadow: none;
      color: $bahamablue !important;
      background-color: transparent !important;
    }
  }
  
.text-login-already{
    color: $freespeechred;
    font-weight: 400;
    padding-bottom: 2px;
}
.yocard-font-icon{
    color: $outrageousorange !important;
}
.login-sign-up-text-register{
    color: $scorpion;
    font-size: 14px;
    font-weight: 600;
}
.login-sign-up-icon-register{
    color: $eucalyptus;
}
.yocard-gender-text{
    color: $davysgrey;
    font-size: 13px;
    font-weight: 500;
}
.image-top-left-register {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 150px;
    margin: -70px;
    z-index: -1 !important;
    background: linear-gradient(125deg, $easternblue, $lightskyblue);
    background-size: cover !important;
}

.image-bottom-right-register {
    position: absolute;
    bottom: 0 !important;
    right: 0;
    width: 150px;
    height: 150px;
    margin: -80px;
    z-index: -1;
    background-image: url('./../../../../public/images/login-register/dots-blue.png');
    background-size: cover;
}
.text-whatsapp {
    font-size: 12px !important;
    color: $salem;
}
.text-email-valid{
    color: $salem;
}
.text-email-invalid{
    color: $outrageousorange;
}
.avatar-user-register-preview {
    margin-right: 30px;
    margin-bottom: 10px;
    width: 120px;
    height: 120px;
    padding: 2px;
    border: 1px solid $isabelline;
}
.register-file-upload-box {
    border: 1px dashed #b6bed1;
    background-color: #f0f2f7;
    border-radius: 4px;
    min-height: 80px;
    position: relative;
    overflow: hidden;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8194aa;
    font-weight: 400;
    font-size: 15px;
}
.register-file-upload-box .register-file-upload-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.register-file-upload-input[disabled] {
    cursor: not-allowed;
}
.register-file-link {
    color: #475f7b;
    text-decoration: underline;
    margin-left: 3px;
}

.register-file-upload-box .file-link:hover {
    text-decoration: none;
}
.width-100{
    width: 1500px !important;
  }
@media screen and (max-width: 576px) {
    .vertical-align-register{
        border: none;
        // padding-top: 50px;
    }

}
