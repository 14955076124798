@import '../../styles/scss/index.scss';

.unauthorized-container {
    color: $outrageousorange;
}
.error-header{
    font-size: 150px;
    font-weight: 700;
}
.error-sub-header{
    font-size: 50px;
    font-weight: 400;
}

.vert-center-unauthorized {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translate(-10%, -40%);
}
.login-back {
    padding: .3rem 1rem .3rem 1rem;
    color: $easternblue;
    text-decoration: none;
}
@media screen and (max-width: 576px) {
    .vert-center-unauthorized {
        margin: 0;
        position: absolute;
        top: 30% !important;
        left: 30% !important;
        transform: translate(-30%, -40%) !important;
    }
}