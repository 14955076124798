@import '../../../../styles/scss/index.scss';

.stopr-form-card {
    padding: 5px 8px;
    background-color: $white;
    border: 1px solid $hawkesblue;
    box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
    border-radius: 5px;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS devices */
}
.stopr-approve-button{
    font-size: 16px;
    color: $salem;
}
.stopr-reject-button{
    font-size: 16px;
    color: $freespeechred;
}
.stopr-card-circle {
    border-radius: 50% !important;
    height: 300px;
    width: 300px;
}
.stopr-text-lite-white{
    font-size: 12px;
    font-weight: 600;
    color:  $white;
}
.stopr-text-lite-radio{
    font-size: 13px;
    font-weight: 600;
    color:  $davysgrey;
}
.stopr-bg-white{
    background-color:  $white;
    height: 36px;
    border-radius: 5px;
    align-items: center;
    // justify-content: center;
}
.stopr-circle_container {
    width: 160px;
    height: 160px;
    margin: 0;
    padding: 0;
}

.stopr-circle_main {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 13px solid $jacksonspurple;
    margin: 0;
    padding: 0;
}

.stopr-circle_success {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 13px solid $salem;
    margin: 0;
    padding: 0;
}

.stopr-circle_error {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 13px solid $rust;
    margin: 0;
    padding: 0;
}

.stopr-circle_text_container {
    width: 70%;
    height: 70%;
    max-width: 70%;
    max-height: 70%;
    margin: 0;
    padding: 0;
    position: relative;
    left: 15%;
    top: 15%;
    transform-style: preserve-3d;
}

.stopr-circle_text {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    font-weight: 800;
    color: $onyx;
}

.stopr-card-dash-header {
    font-size: 17px;
    font-weight: 600;
    color: $onyx;
}

.stopr-file-upload-box {
    border: 1px dashed #b6bed1;
    background-color: #f0f2f7;
    border-radius: 4px;
    min-height: 110px;
    position: relative;
    overflow: hidden;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8194aa;
    font-weight: 400;
    font-size: 15px;
}

.stopr-file-upload-box .stopr-file-upload-input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.stopr-file-upload-input[disabled] {
    cursor: not-allowed;
}

.stopr-file-link {
    color: #475f7b;
    text-decoration: underline;
    margin-left: 3px;
}

.stopr-file-upload-box .file-link:hover {
    text-decoration: none;
}

.stopr-tab {
    border: none !important;
}
.stopr-row-tab {
    display: block;
}
.stopr-column-tab-left {
    float: left;
}
.stopr-column-tab-right {
    float: right;
  }
.stopr-nav-tabs {
    color: $bombay !important;
    background-color: $mintcream !important;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 600;
    margin: 1px 1px !important;
    padding: 5px 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stopr-nav-tabs.active {
    color: $trumpetflower !important;
    background: $white !important;
    letter-spacing: 1px;
    border: none;
    border-bottom: 1px solid $isabelline !important;
}

.stopr-tab-icon {
    font-size: 17px;
    margin-right: 10px;
}
.stopr-card-box {
    border: 1px dashed #d3e0ff;
    background-color: $mintcream;
    border-radius: 4px;
    padding: 5px 15px;
}
.stopr-file-image-ref {
    border: none;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #eaecf1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #475f7b;
    padding: 5px;
    margin: 5px;
    &:focus {
        outline: 0;
        box-shadow: none !important;
      }
      &:hover {
        background-image: linear-gradient(100deg,
        $cccalendartoday 10%,
        #eaecf1 80%);
        color: $onyx !important;
      }
}
.stopr-file-image-ref-success {
    width: 45px;
    height: 45px;
    border: none;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #d3f9e6;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: $salem;
    padding: 3px;
    &:focus {
        outline: 0;
        box-shadow: none !important;
      }
      &:hover {
        background-image: linear-gradient(100deg,
        $cccalendartoday 10%,
        #eaecf1 80%);
        color: $onyx !important;
      }
}
.stopr-file-image-ref-error {
    width: 45px;
    height: 45px;
    border: none;
    background-size: cover;
    border-radius: 5px;
    margin-right: 15px;
    background-color: #f9dac9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: $freespeechred;
    padding: 3px;
    &:focus {
        outline: 0;
        box-shadow: none !important;
      }
      &:hover {
        background-image: linear-gradient(100deg,
        $cccalendartoday 10%,
        #eaecf1 80%);
        color: $onyx !important;
      }
}
.stopr-scroll-container {
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    overflow-x:auto;
    // min-height: 60vh;
}

.stopr-print-circle_container {
    width: 65px;
    height: 65px;
    margin: 0;
    padding: 0;
}
.stopr-print-circle_success {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid $salem;
    margin: 0;
    padding: 0;
}
.stopr-print-circle_error {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid $rust;
    margin: 0;
    padding: 0;
}
.stopr-print-circle_text_container {
    width: 70%;
    height: 70%;
    max-width: 70%;
    max-height: 70%;
    margin: 0;
    padding: 0;
    position: relative;
    left: 15%;
    top: 15%;
    transform-style: preserve-3d;
}

.stopr-print-circle_text {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 11px;
    font-weight: 600;
    color: $onyx;
}
.card-data-format{
    background-color: $jacksonspurple !important;
    color: $white !important;
    border-radius: 5px;
    min-height: 60px;
}
.card-icon{
    height: 25px;
    width: 25px;
    color: $white;
}
.card-text-small-white{
    font-size: 9px;
    font-weight: 400;
    color:  $white;
}
.card-text-large-white{
    font-size: 22px;
    font-weight: 700;
    color:  $white;
}
@media only screen and (max-width: 576px) {
    .stopr-tab-text{
        display: none;
    }
}