@import "../../../styles/scss/index.scss";

.stadmin-margin-top-bottom {
    margin-top: 5px;
}

.stadmincard-dashboard {
    width: 100%;
    margin: 0 auto;
    box-shadow: 5px 5px 10px rgba(22, 24, 26, 0.07);
    border: 1px solid $isabelline;
    border-radius: 10px;
    background-color: $white;
    min-height: 355px;
}
.progress-bar{
    background-color: $bluediamond !important;
}
.stadmin-progress-card-status{
    height: 25px;
    border: transparent;
    border-radius: 2px;
    color: $bluediamond !important;
}
.stadmincard-dashboard-header {
    border: none;
    font-size: 14px;
    font-weight: 600;
    color: $davysgrey;
}

.stadmincard-dash-icon {
    font-size: 22px;
    font-weight: 600;
    color: $slateblue;
}

.stadmincard-card-dash-header {
    font-size: 15px;
    font-weight: 400;
    color: $freespeechred;
}

.stadm-dash-circle_container {
    width: 60px !important;
    height: 60px !important;
    margin: 0;
    padding: 0;
}

.stadm-dash-card-border-container {
    width: 60px !important;
    height: 60px !important;
    margin: 0;
    padding: 0;
  }
.stadm-dash-card-border {
    border: 1px solid $hawkesblue;
    box-shadow: 2px 5px 10px rgba(22, 24, 26, 0.07);
    border-radius: 5px;
  }

.dash-all-header-text {
    font-size: 15px;
    font-weight: 600;
    color: $onyx;
}

.stadmin-adjust-doughnut {
    height: 300px;
    width: auto;
}
.stadmin-adjust-line {
    height: 350px;
    width: auto;
}
.stadmin-horizontal-graph{
    height: auto;
    width: auto;
}
@media only screen and (max-width: 576px) {
    .stadmin-margin-top-bottom {
        margin-top: 50px;
    }
    // .stadmin-adjust-doughnut {
    //     height: 50px;
    //     width: auto;
    // }
}
// UL Properties Status details
.accent-color1 {
   color: #41516C
}
.accent-color2 {
    color: #FBCA3E
}
.ul-status {
    display: grid;
    grid-template-columns: .25rem;
    grid-auto-columns: max-content;
    column-gap: 3rem;
    list-style: none;
    width: min(60rem, 100%);
    margin-inline: auto;
}
.li-status {
    grid-column: 2;
    margin-inline: 1.1rem;
    grid-row: span 2;
    display: grid;
    grid-template-rows: min-content min-content min-content;
}
.ul-status .li-status .date {
    height: 1.5rem;
    margin-inline: calc(2.4rem * -1);
    text-align: center;
    background-color: #4b6084;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    display: grid;
    place-content: center;
    position: relative;
    border-radius: calc(3rem / 2) 0 0 calc(3rem / 2);
  }
  
  /* date flap */
  .ul-status .li-status .date::before {
    content: "";
    width: 1.1rem;
    aspect-ratio: 1;
    background: #4d648c;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
    position: absolute;
    top: 100%;
  
    clip-path: polygon(0 0, 100% 0, 0 100%);
    right: 0;
  }
  
  /* circle */
  .ul-status .li-status .date::after {
    content: "";
    position: absolute;
    width: 1.2rem;
    aspect-ratio: 1;
    background: #FBCA3E;
    border: 0.3rem solid #FBCA3E;
    border-radius: 50%;
    top: 50%;
    transform: translate(10%, -50%);
    // right: calc(100% + var(2rem) + var(1.5rem) / 2);
  }
.title-status{
    font-size: 15px;
    font-weight: 500;
    color: $onyx;
    padding: 3px 10px;
    box-shadow: 0 0 20px 5px rgba(1, 12, 2, 0.5);
}
.styled-table {
    width: 100% !important;
    border-collapse: collapse;
    margin-top: 20px;
    border-bottom: 1px solid #f9f4f4;
    border-left: 1px solid #f9f4f4;
  }
  
  .styled-table th, .styled-table td {
    border: 1px solid #f9f4f4;
    padding: 8px;
    text-align: left;
    width: 1200px !important;
  }
  
  .styled-table th {
    background-color: #f2f2f2;
    border-bottom: 1px solid #f9f4f4;
  }
  
  .styled-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
    border-bottom: 1px solid #f9f4f4;
  }
  .info-stadm-icon{
    color: $silver;
    font-size: 12px;
  }
